import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, computed, onUnmounted, onMounted, nextTick } from 'vue';
export default {
  __name: 'index',
  props: {
    time: {
      type: Number,
      default: 0.5
    },
    left: {
      type: [Number],
      default: 0
    },
    top: {
      type: [Number],
      default: 0
    },
    zoom: {
      type: [Number],
      default: 1
    },
    allowance: {
      type: [Number, String],
      default: 50
    }
  },
  emits: ['onShow'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    _useCssVars(_ctx => ({
      "764b039a": _unref(transform),
      "40ed5fa6": _unref(time),
      "ab8fdedc": _unref(opacity)
    }));
    const addUnits = obj => isNaN(obj) ? obj : `${obj}px`;
    const myRef = ref(null);
    const show = ref(false);
    const time = computed(() => isNaN(props.time) ? `${props.time}` : `${props.time}s`);
    const transform = computed(() => show.value ? 'translate3d(0, 0, 0) ' : `translate3d(${addUnits(props.left)}, ${addUnits(props.top)}, 0) scale(${props.zoom})`);
    const opacity = computed(() => show.value ? 1 : 0);
    setTimeout(() => {
      //let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //!scrollTop && onScroll();
      onScroll();
    }, 0);
    let inAnimation = false;
    const onScroll = () => {
      if (inAnimation) {
        return;
      }
      //let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //console.log(scrollTop, myRef.value.offsetTop, window.innerHeight, initialTop);
      //console.log(scrollTop + window.innerHeight - (initialTop + 50));
      if (window.innerHeight > myRef.value.getBoundingClientRect().top + props.allowance * 1) {
        show.value = true;
        emits('onShow');
        inAnimation = true;
        setTimeout(() => {
          inAnimation = false;
        }, props.time * 100);
      } else {
        show.value = false;
      }
    };
    window.addEventListener('scroll', onScroll);
    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "my-transition",
        ref_key: "myRef",
        ref: myRef
      }, [_renderSlot(_ctx.$slots, "default", {
        show: show.value
      })], 512);
    };
  }
};