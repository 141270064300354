import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home/home.vue'
import { loadImg } from '@/utils'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "首页",
      firstImg: require('@/assets/home/bg01.png')
    },
    component: home
  },
  {
    path: '/solution',
    name: 'solution',
    // component: () => import('../views/solution/solution.vue'),
    children: [{
      path: 'financial',
      name: 'financial',
      meta: {
        title: "业务介绍",
        subtitle: '赋界金融',
        firstImg: require('@/assets/solution/solution_bg.png')
      },
      component: () => import('@/views/solution/financial.vue'),
    },
    {
      path: 'vehicle',
      name: 'vehicle',
      meta: {
        title: "业务介绍",
        subtitle: '赋界二手商用车',
        firstImg: require('@/assets/vehicle/bg01.png')
      },
      component: () => import('@/views/solution/vehicle.vue'),
    },
    {
      path: 'logistics',
      name: 'logistics',
      meta: {
        title: "业务介绍",
        subtitle: '赋界物流',
        firstImg: require('@/assets/logistics/img01.png')
      },
      component: () => import('@/views/solution/logistics.vue'),
    },
    {
      path: 'cardRepair',
      name: 'cardRepair',
      meta: {
        title: "业务介绍",
        subtitle: '赋界卡修',
        firstImg: require('@/assets/cardRepair/img/img01.png')
      },
      component: () => import('@/views/solution/cardRepair.vue'),
    },
    {
      path: 'comeOn',
      name: 'comeOn',
      meta: {
        title: "业务介绍",
        subtitle: '赋界能源',
        firstImg: require('@/assets/comeOn/bg01.png')
      },
      component: () => import('@/views/solution/comeOn.vue'),
    },
    {
      path: 'etc',
      name: 'etc',
      meta: {
        title: "业务介绍",
        subtitle: '赋界ETC',
        firstImg: require('@/assets/etc/bg01.png')
      },
      component: () => import('@/views/solution/etc.vue'),
    },]
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '新闻资讯',
      firstImg: require('@/assets/news/bg_news_banner.png')
    },
    component: () => import('../views/news/news.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    meta: {
      title: "新闻详情"
    },
    component: () => import('../views/news/newsDetail.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/test.vue')
  },
  {
    path: '/IntoFJ',
    name: 'IntoFJ',
    children: [
      {
        path: 'case',
        name: 'case',
        meta: {
          title: "走进赋界",
          subtitle: '公司案例',
          firstImg: require('@/assets/intofj/bg_case_banner.png')
        },
        component: () => import('../views/IntoFJ/case.vue')
      },
      {
        path: '/IntoFJ/careers',
        name: 'careers',
        meta: {
          title: "走进赋界",
          subtitle: '招贤纳士',
          firstImg: require('@/assets/intofj/hand.png')
        },
        component: () => import('../views/IntoFJ/careers.vue')
      },
      // 关于我们
      {
        path: '/IntoFJ/aboutus',
        name: 'aboutus',
        meta: {
          title: "走进赋界",
          subtitle: '了解赋界',
          firstImg: require('@/assets/intofj/0.bg-tuya.png')
        },
        component: () => import('../views/IntoFJ/aboutus.vue')
      },
      {
        path: '/IntoFJ/innovation',
        name: 'innovation',
        meta: {
          title: "走进赋界",
          subtitle: '创新理念',
          firstImg: require('@/assets/intofj/bigbig.png')
        },
        component: () => import('../views/IntoFJ/innovation.vue')
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savePosition) {
    //console.log(to, from, savePosition)
    if (!!from.name && !!savePosition) {
      return savePosition;
    }
    if (to.name == from.name) {
      return { top: 0 }
    }
    return { top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.firstImg) {
    loadImg(to.meta.firstImg);
    next();
  } else {
    next();
  }
});

export default router
