import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils'
import config from '@/config'
import { message } from 'ant-design-vue'

// 创建 axios 实例
const myRequest = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.NODE_ENV === 'production' || process.env.VUE_APP_ENV == 'test' ? config.baseUrl : '/api',
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  let code = 0
  if (error.data && error.data.status) {
    code = error.data.status
  } else if (error.data && error.data.code) {
    code = error.data.code
  } else if (error.response.status) {
    code = error.response.status
  }
  if (code) {
    if (code === 500) {
      message.error('服务器错误！')
    }
    if (code === 403) {
      console.log(403)
    }
    if (code === 401) {
      message.error('登录过期，请重新登录！')
      store.dispatch('Logout')
      return
    }
  }
  return Promise.reject(error)
}

// request interceptor
myRequest.interceptors.request.use(config => {
  const token = getToken()
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers.source = 'operating-platform-web'
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, errorHandler)

// response interceptor
myRequest.interceptors.response.use((response) => {
  if ((typeof response.data === 'object' && response.data.hasOwnProperty('code') && response.data.code !== 0) || (response.data.status && response.data.status != 200) || response.data.data === false) { // 需自定义
    return errorHandler(response)
  }
  return response
}, errorHandler)

export const download = config => {
  return new Promise((resolve, reject) => {
    myRequest({ ...config, responseType: 'blob', timeout: 60 * 1000 }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export const request = config => {
  return new Promise((resolve, reject) => {
    if (config.url.indexOf('.json') != -1) {
      config.url = `${window.location.origin}/json${config.url}`
      config.method = 'get'
      console.log(JSON.stringify(config.data))
    }
    myRequest(config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export default request
