import MyTransitionComponent from './transition';
import MyUpComponent from './transition/my-up';
import MyDownComponent from './transition/my-down';
import MyLeftComponent from './transition/my-left';
import MyRightComponent from './transition/my-right';
import MyZoomComponent from './transition/my-zoom';

const MyComponen = {
  install: function (Vue) {
    Vue.component("MyTransition", MyTransitionComponent);
    Vue.component("MyUp", MyUpComponent);
    Vue.component("MyDown", MyDownComponent);
    Vue.component("MyLeft", MyLeftComponent);
    Vue.component("MyRight", MyRightComponent);
    Vue.component("MyZoom", MyZoomComponent);
  }
}

export default MyComponen
