import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import MyTransition from './index.vue';
export default {
  __name: 'my-zoom',
  props: {
    offset: {
      type: [Number],
      default: 0.6
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(MyTransition, {
        class: "my-up",
        zoom: props.offset
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["zoom"]);
    };
  }
};