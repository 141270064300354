import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-087a69d4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content_qrcode center"
};
const _hoisted_2 = {
  class: "safeWith flex"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
  class: "qrcodes"
};
const _hoisted_6 = {
  class: "qrcode_explains"
};
const _hoisted_7 = {
  key: 0,
  class: "text qrcode_explain"
};
const _hoisted_8 = {
  class: "text qrcode_explain"
};
const _hoisted_9 = {
  class: "text qrcode_explain"
};
const _hoisted_10 = {
  class: "qrcode_bottom"
};
import { pageSize, pageClass } from '@/utils';
export default {
  __name: 'index',
  props: {
    title: {
      type: [String],
      default: ''
    },
    subtitle: {
      type: [String],
      default: ''
    },
    qrcode: {
      type: [String, Array],
      default: ''
    },
    explain: {
      type: [String, Array],
      default: ''
    },
    extra: {
      type: [String],
      default: ''
    },
    screenshot: {
      type: [String],
      default: ''
    },
    doAnimation: {
      type: [Boolean],
      default: false
    }
  },
  emits: ['mouseenter', 'mouseleave'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_my_right = _resolveComponent("my-right");
      const _component_my_left = _resolveComponent("my-left");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_my_right, {
        class: "left block"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "qrcode_title",
          innerHTML: props.title
        }, null, 8, _hoisted_3), _createElementVNode("div", {
          class: "text",
          innerHTML: props.subtitle
        }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [typeof props.qrcode == 'string' ? _withDirectives((_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass({
            doAnimation: props.doAnimation
          }),
          onMouseenter: _cache[0] || (_cache[0] = $event => emits('mouseenter')),
          onMouseleave: _cache[1] || (_cache[1] = $event => emits('mouseleave'))
        }, null, 34)), [[_directive_lazy, props.qrcode]]) : Array.isArray(props.qrcode) ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_withDirectives(_createElementVNode("img", {
          class: _normalizeClass({
            doAnimation: props.doAnimation
          }),
          onMouseenter: _cache[2] || (_cache[2] = $event => emits('mouseenter')),
          onMouseleave: _cache[3] || (_cache[3] = $event => emits('mouseleave'))
        }, null, 34), [[_directive_lazy, props.qrcode[0]]]), _withDirectives(_createElementVNode("img", {
          class: _normalizeClass({
            doAnimation: props.doAnimation
          }),
          onMouseenter: _cache[4] || (_cache[4] = $event => emits('mouseenter')),
          onMouseleave: _cache[5] || (_cache[5] = $event => emits('mouseleave'))
        }, null, 34), [[_directive_lazy, props.qrcode[1]]])], 64)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_6, [typeof props.explain == 'string' ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(props.explain), 1)) : Array.isArray(props.explain) ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createElementVNode("div", _hoisted_8, _toDisplayString(props.explain[0]), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(props.explain[1]), 1)], 64)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, _toDisplayString(props.extra), 1), _renderSlot(_ctx.$slots, "bottom")]),
        _: 3
      }), _createVNode(_component_my_left, {
        class: "right block"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("img", {
          class: _normalizeClass({
            doAnimation: props.doAnimation
          }),
          onMouseenter: _cache[6] || (_cache[6] = $event => emits('mouseenter')),
          onMouseleave: _cache[7] || (_cache[7] = $event => emits('mouseleave'))
        }, null, 34), [[_directive_lazy, props.screenshot]])]),
        _: 1
      })])]);
    };
  }
};