import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MyComponen from '@/components';
import { init } from '@/utils';
import '@/style/global.scss';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

const app = createApp(App)

app.config.globalProperties.log = msg => console.log(msg);
app.config.globalProperties.alert = msg => alert(msg);
app.config.globalProperties.window = window;
// app.config.globalProperties.baseUrl = baseUrl;

app.use(store)
app.use(router)
app.use(MyComponen)
app.use(init)
app.use(Antd)
app.mount('#app') 
