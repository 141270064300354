// 配置 开发环境、生产环境baseurl地址
const CONFIG = {
  // 测试环境配置
  test: {
    baseUrl: '',
    isDev: true
  },
  // 开发环境配置
  development: {
    baseUrl: 'http://192.168.2.235:22000',
    isDev: true
  },
  // 生产环境配置
  production: {
    baseUrl: 'https://www.fujiekeji.com.cn',
    isDev: false
  }
}
//export default CONFIG[process.env.VUE_APP_ENV || process.env.NODE_ENV]

module.exports = CONFIG[process.env.VUE_APP_ENV || process.env.NODE_ENV];
