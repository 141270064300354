import { baseUrl } from '@/config'

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const PROJECT_NAME = 'fujie';

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setToken = token => localStorage.setItem(ACCESS_TOKEN, token);


/**
 * @description 格式化时间
 * @param {String|Number} dateTime 需要格式化的时间戳
 * @param {String} fmt 格式化规则 yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合 默认yyyy-mm-dd
 * @returns {string} 返回格式化后的字符串
 */
export const timeFormat = (dateTime = null, formatStr = 'yyyy-mm-dd') => {
  let date
  // 若传入时间为假值，则取当前时间
  if (!dateTime) {
    date = new Date()
  }
  // 若为unix秒时间戳，则转为毫秒时间戳（逻辑有点奇怪，但不敢改，以保证历史兼容）
  else if (/^\d{10}$/.test(dateTime.toString().trim())) {
    date = new Date(dateTime * 1000)
  }
  // 若用户传入字符串格式时间戳，new Date无法解析，需做兼容
  else if (typeof dateTime === 'string' && /^\d+$/.test(dateTime.trim())) {
    date = new Date(Number(dateTime))
  }
  // 其他都认为符合 RFC 2822 规范
  else {
    // 处理平台性差异，在Safari/Webkit中，new Date仅支持/作为分割符的字符串时间
    date = new Date(typeof dateTime === 'string' ? dateTime.replace(/-/g, '/') : dateTime)
  }

  const timeSource = {
    'y': date.getFullYear().toString(), // 年
    'm': (date.getMonth() + 1).toString().padStart(2, '0'), // 月
    'd': date.getDate().toString().padStart(2, '0'), // 日
    'h': date.getHours().toString().padStart(2, '0'), // 时
    'M': date.getMinutes().toString().padStart(2, '0'), // 分
    's': date.getSeconds().toString().padStart(2, '0') // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }

  for (const key in timeSource) {
    const [ret] = new RegExp(`${key}+`).exec(formatStr) || []
    if (ret) {
      // 年可能只需展示两位
      const beginIndex = key === 'y' && ret.length === 2 ? 2 : 0
      formatStr = formatStr.replace(ret, timeSource[key].slice(beginIndex))
    }
  }

  return formatStr
}

export const loadImg = (src, config = { timeout: 500 }) => {
  return new Promise((res, rej) => {
    const firstImg = document.createElement('img');
    firstImg.src = src;
    firstImg.onload = () => {
      res(true);
    }
    firstImg.onerror = () => {
      res(false);
    }
    if (config.timeout) {
      setTimeout(() => {
        res(false);
      }, config.timeout)
    }
  })
}

import { computed, ref } from 'vue';
const pageWidth = ref(window.innerWidth);
window.addEventListener('resize', () => {
  pageWidth.value = window.innerWidth;
})
export const pageSize = computed(() => {
  if (pageWidth.value >= 1200) {
    return 'big';
  }
  if (pageWidth.value <= 750) {
    return 'small';
  }
  return 'middle';
});

export const isShowMenu = ref(false);

export const pageClass = computed(() => {
  if (pageWidth.value >= 1200) {
    return { 'big': true };
  }
  if (pageWidth.value <= 750) {
    return { 'small': true };
  }
  return { 'middle': true };
});

const lazy = {
  mounted(el, binding, vnode) {
    if (el.tagName != 'IMG') {
      el.style.backgroundRepeat = `no-repeat`;
      el.style.backgroundPosition = `center`;
      el.style.backgroundSize = `cover`;
    }
    binding.arg && (el.style.height = `${binding.arg}px`);
    vnode.monitor = () => {
      if (window.innerHeight > el.getBoundingClientRect().top - 50) {
        if (el.tagName == 'IMG') {
          el.src = binding.value;
        } else {
          el.style.backgroundImage = `url(${binding.value})`;
        }
        vnode.monitor && window.removeEventListener('scroll', vnode.monitor);
      }
    }
    vnode.monitor();
    window.addEventListener('scroll', vnode.monitor);
  },
  updated(el, binding, vnode, prevVnode) {
    if (window.innerHeight > el.getBoundingClientRect().top - 50) {
      if (el.tagName == 'IMG') {
        el.src = binding.value;
      } else {
        el.style.backgroundImage = `url(${binding.value})`;
      }
    }
  },
  unmounted(el, binding, vnode) {
    vnode.monitor && window.removeEventListener('scroll', vnode.monitor);
  }
}

export const init = {
  install: function (Vue) {
    Vue.directive('lazy', lazy);
  }
}
