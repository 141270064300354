import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import headerNav from '@/components/headerNav.vue';
import bottomModule from '@/components/bottomModule.vue';
import { watch } from 'vue';
import { pageSize, pageClass } from '@/utils';
import { clickRecord } from '@/api/home/index';
import device from 'current-device';
export default {
  __name: 'App',
  setup(__props) {
    watch(() => pageSize.value, () => {
      document.getElementById('app').className = pageSize.value;
    }, {
      immediate: true
    });
    let accessType = undefined;
    if (device.mobile()) {
      accessType = 'mobile';
    } else if (device.ipad()) {
      accessType = 'ipad';
    } else if (device.desktop()) {
      accessType = 'desktop';
    }
    let navigationType = window.performance.getEntriesByType("navigation")[0].type;
    if (navigationType == 'navigate' || navigationType == 'back_forward') {
      clickRecord({
        accessType
      }).then(res => {
        // console.log(res);
      });
    }
    //document.getElementById('app').style.overflowX = window.innerWidth > 1200 ? 'hidden' : 'visible';
    //document.body.style.overflowX = window.innerWidth > 1200 ? 'hidden' : 'visible';
    //window.addEventListener('resize', function () {
    //  document.getElementById('app').style.overflowX = window.innerWidth > 1200 ? 'hidden' : 'visible';
    //  document.body.style.overflowX = window.innerWidth > 1200 ? 'hidden' : 'visible';
    //});

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(headerNav), _createVNode(_component_router_view), _createVNode(bottomModule)], 64);
    };
  }
};