import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import MyTransition from './index.vue';
export default {
  __name: 'my-up',
  props: {
    offset: {
      type: [Number],
      default: 200
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(MyTransition, {
        class: "my-up",
        top: props.offset
      }, {
        default: _withCtx(({
          show
        }) => [_renderSlot(_ctx.$slots, "default", {
          show: show
        })]),
        _: 3
      }, 8, ["top"]);
    };
  }
};